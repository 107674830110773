import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./utils/styling";
import GlobalStyle from "./components/GlobalStyles";

import Container from "./components/Container";
import Hero from "./components/Hero";
import ContactSection from "./components/ContactSection";
import Footer from "./components/Footer";
import "./utils/fontface.css";
import Intro from "./components/Intro";
import AccordionsHolder from "./components/AccordionsHolder";


function App() {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Hero />
        <Intro />
        <AccordionsHolder />
        <ContactSection />
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
