import React from "react";
import AccordionGroup from "./AccordionGroup";
import Accordion from "./Accordion";
import styled from "styled-components";

const CenteredText = styled.p`
  @media (${(props) => props.theme.breakpoints.md}) {
    text-align: center;
  }
`;

const AccordionsHolder = () => {
  return (
    <AccordionGroup>
      <Accordion scrollTo={true} title={<p>What is Our House trying to do?</p>}>
        <CenteredText>
          Our House aims to{" "}
          <b>
            activate public imagination and desire for political systems change.
          </b>
        </CenteredText>
        <p>We will achieve this by:</p>
        <ol>
          <li>
            <b>Demonstrating and defining a new model for democracy.</b>
            &nbsp; Our House will be centred on a citizen-led process that will
            explore the challenges in our current system and the possibilities
            of how people hold power, from citizens assemblies to proportional
            representation. The outcomes of this process will be determined by
            the people who participate, drawing on democratic innovations and
            creative practice to develop a new model for democracy and
            decision-making.
          </li>
          <li>
            <b>Igniting a multi-nation debate.</b>&nbsp; An effective media and
            public relations strategy will push engaging content through local
            and national channels. This will be supported by local people&apos;s
            assemblies feeding into the national forum (and vice-versa) so that
            people will be able to both see and experience new ways of holding
            power.
          </li>
          <li>
            <b>Mobilising campaigns for political reform.</b>&nbsp; The UK has a{" "}
            <a
              href="https://democracynetwork.org.uk/"
              target="_blank"
              rel="noreferrer noopener"
            >
              rich network
            </a>{" "}
            of organisations campaigning for political and democratic reform,
            and Our House aims to support this network by catalysing interest
            and awareness in democratic reform. This process would be designed
            and implemented with support from campaign groups.
          </li>
        </ol>
      </Accordion>
      {/* <Accordion scrollTo={false} title={<p>Why now?</p>}>
            <p></p>
          </Accordion> */}
      <Accordion scrollTo={true} title={<p>What&apos;s the plan?</p>}>
        <p>
          Our House will launch in 2025. There will be three core components:
        </p>
        <ul>
          <li>
            <b>A forum on the future of democracy.</b>&nbsp; 150 people from
            across the UK will participate in a three month long forum to
            imagine a new democratic system. This will be a creative and open
            process, where people consider options for political systems change
            and how we are represented, from voting to random selection. The
            forum will be structured similarly to a citizens&apos; assembly,
            where a randomly selected and representative sample of the
            population forms a &apos;mini public&apos;, and is given the time
            and resources to deliberate and co-create together.
          </li>
          <li>
            <b>Amplifying the forum through local and national media.</b>
            &nbsp; In partnership with media organisations, campaigners, and
            creatives, we will amplify the conversations and content of the
            forum. This will include forum participants sharing their
            experiences on local news networks, in engaging content on social
            media, and through an accompanying documentary.
          </li>
          <li>
            <b>Broaden the conversation across the country.</b>&nbsp; A network
            of local popular assemblies will allow people to experience
            facilitated deliberation in their local area, reaching both those
            engaged and those who don&apos;t typically participate in the
            existing system. Here, people from communities across the country,
            whether interested in the process or established champions within
            their community, will be able to access training and draw down funds
            to facilitate local conversations.
          </li>
        </ul>
        <p>
          At the end of the process, the people who have participated in Our
          House will have created a new vision and model for how democracy can
          work in the UK. We&apos;ll take this forward within the existing
          campaign ecosystem to push and promote these ideas for reform with a
          new wave of popular support and awareness. This will happen alongside
          a narrative-shifting documentary following the process and the people
          involved. The success of Our House will be measured by public
          awareness of and demand for a transformed democratic system.
        </p>
      </Accordion>
      <Accordion scrollTo={true} title={<p>Why now?</p>}>
        <p>
          Beyond a Labour landslide, the 2024 general election delivered a clear
          result: our political system isn&apos;t working. How can it be that in
          a cost of living crisis, environmental collapse, and a failing health
          system, 19 million people decided not to vote at all? How can it be,
          for those who did vote, that our electoral system produced a landslide
          government with 63% of the seats yet only 34% of the vote share?
        </p>
        <p>
          In a time of crisis and collapse, we need a democratic system that
          enables good ideas to thrive. One that enables people to work together
          and overcome their differences. But our current political system was
          designed to maintain the status quo and exaggerate division. Whether a
          citizen or politician, everyone struggles to create meaningful change
          in this system. And there are no easy choices on the road ahead of us,
          so we need a democracy that allows us to shape the country we want for
          our future.
        </p>
        <p>
          It&apos;s clear that our Victorian political system is no longer
          working, but is voting every five years the best we can do? The new
          government has committed to explore political reform, so now is the
          time to build a movement towards a new democratic system. One that is
          citizen-led. One that is incorruptible. One that connects us all.
        </p>
        <p>Now is the time to build a new democracy: to build Our House.</p>
      </Accordion>
      <Accordion scrollTo={true} title={<p>Who is involved?</p>}>
        <p>
          Our House is an alliance of creatives, democracy practitioners,
          community organisers, activists, funders, campaigners, and more. We
          will be sharing more about those behind Our House in the coming
          months. Stay tuned.
        </p>
      </Accordion>
    </AccordionGroup>
  );
};

export default AccordionsHolder;
