import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  font-size: 1.2rem;

  @media (${(props) => props.theme.breakpoints.md}) {
    text-align: center;
  }
`;

const Intro = () => {
  return (
    <Holder>
      <p>
        We need to update our political system. 19 million people chose not to
        vote in the 2024 general election, despite the huge challenges we all
        face. And while trust in UK politics is at an all-time low, very few
        ideas for political reform capture the public's attention.
      </p>
      <p>
        Our House is building a citizen-led movement towards a people-powered
        system. We are an alliance of democracy practitioners, creatives,
        community organisers, activists, and campaigners building the
        scaffolding for a citizen-led future.
      </p>
      <p>
        Yes, we need to update our Victorian electoral system, but now is the
        time to look beyond changing how we vote. Let&apos;s come together to
        answer collectively: how do we want to run the country?
      </p>
    </Holder>
  );
};

export default Intro;
