//  The Chevron points south by default. Use a CSS transform to rotate it.

export default function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 8"
      fill="currentColor"
      aria-hidden="true" // always show with text or an aria-label
      role="img"
    >
      <path d="M8.52101 6.05198 14.5 1.00488 13.3425.0286277 7.36328 5.07533 1.65713.258724.5 1.23527l6.86309 5.79335 1.15792-.97664Z" />
    </svg>
  );
}
