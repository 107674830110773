import React, { Component } from "react";
import styled from "styled-components";

const Holder = styled.div`
  display: grid;
  gap: 4rem;
  max-width: ${(props) => props.theme.typography.maxScreen}px;
  margin: 2rem auto 0;
  padding: 1.5rem;

  @media (${(props) => props.theme.breakpoints.md}) {
    padding: 1rem 4rem;
    gap: 3rem;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

class Container extends Component {
  render() {
    return <Holder>{this.props.children}</Holder>;
  }
}

export default Container;
