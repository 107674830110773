import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

const Holder = styled.div`
  form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
`;

const Name = styled.div`
  grid-column: span 4;
  @media (${(props) => props.theme.breakpoints.md}) {
    grid-column: span 2;
  }
`;

const Email = styled.div`
  grid-column: span 4;
  @media (${(props) => props.theme.breakpoints.md}) {
    grid-column: span 2;
  }
`;

const Involvement = styled.div`
  grid-column: span 4;
  @media (${(props) => props.theme.breakpoints.md}) {
    grid-column: span 2;
  }
`;

const Message = styled.div`
  grid-column: span 4;
  @media (${(props) => props.theme.breakpoints.md}) {
    grid-column: span 2;
  }
`;

const Consent = styled.div`
  display: grid;
  gap: 0.5rem;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    @media (${(props) => props.theme.breakpoints.md}) {
      flex-direction: row;
      align-items: center;
    }
  }

  input[type="checkbox"] {
    appearance: none; /* Hide the default checkbox */
    position: relative; /* Positioning for pseudo-elements */
    width: 20px; /* Custom width */
    height: 20px; /* Custom height */
    background-color: ${(props) => props.theme.colours.white};
    border: 1px solid ${(props) => props.theme.colours.green}; /* Light grey border */
    border-radius: 2px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    margin: 0; /* Reset the margin */
  }
  input[type="checkbox"]:checked:before {
    content: ""; /* Necessary for the pseudo-element */
    position: absolute;
    left: 5px; /* Position from the left */
    top: 1px; /* Position from the top */
    width: 6px; /* Width of the checkmark */
    height: 12px; /* Height of the checkmark */
    border: solid ${(props) => props.theme.colours.green}; /* Color of the checkmark */
    border-width: 0 2px 2px 0; /* Thickness of the checkmark */
    transform: rotate(45deg); /* Angle of the checkmark */
    margin: 0; /* Reset the margin */
  }
  grid-column: span 4;
  label {
    font-size: 0.8rem;
    cursor: pointer; /* Pointer cursor on hover */
  }
`;

const Submit = styled.div`
  grid-column: span 4;

  input[type="submit"] {
    background-color: ${(props) => props.theme.colours.white} !important;
    color: ${(props) => props.theme.colours.green} !important;
    cursor: pointer;
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("* Name is required."),
  email: Yup.string()
    .email("* Enter a valid email.")
    .required("* Email is required."),
  subject: Yup.string(),
  involvement: Yup.string().required("* Involvement level is required."),
  message: Yup.string(),
  consent: Yup.boolean()
    .required("Consent is required")
    .oneOf([true], "You need to accept the terms and conditions."),
});

function ContactForm() {
  //  The location in our /public folder where the HTML version of the form
  //  exists for Netlify detection
  const htmlFormLocation = "/contact-form.html";

  const initialValues = {
    name: "",
    email: "",
    involvement: "",
    message: "",
    consent: false,
  };

  //  State
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [loadTime, setLoadTime] = useState(null);

  //  Submission Flow
  async function handleSubmit(values, { resetForm }) {
    //  Detect whether this submission is too fast (bot)
    //  Refresh the page if so to fake a successful submission
    // const isTooFast = Date.now() - loadTime < 2000;
    // if (isTooFast) return window.location.reload();

    const formData = {
      ...values,
      "form-name": "contact",
    };

    try {
      //  Submit to Netlify
      await fetch(htmlFormLocation, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body: new URLSearchParams(formData).toString(),
      });

      //  Show success state
      setIsSuccess(true);

      //  Reset the form
      resetForm({
        values: initialValues,
      });

      //  Remove success state
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    } catch (error) {
      //  TODO: Clear in production
      console.error(error);
      alert(
        "Something went wrong! Could you please try again or email us directly."
      );

      //  Show generic error message
      setIsError(true);
    }
  }

  //  Set the loading time of the form
  // useEffect(() => {
  //   setLoadTime(Date.now());
  // }, []);

  return (
    <Holder>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form
            className="form"
            method="post"
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="sweettreat"
            noValidate
          >
            <Field type="hidden" name="form-name" value="contact" />
            <Name className="fieldset">
              <label htmlFor="name" className="label">
                Name
              </label>
              <Field className="input" type="text" name="name" />
              <ErrorMessage
                className="errorMsg"
                name="name"
                component="small"
              />
            </Name>
            <Email className="fieldset">
              <label htmlFor="email" className="label">
                Email
              </label>
              <Field className="input" type="text" name="email" />
              <ErrorMessage
                className="errorMsg"
                name="email"
                component="small"
              />
            </Email>
            <Involvement>
              <label htmlFor="involvement" className="label">
                Involvement
              </label>
              <div className="fieldset select">
                <Field as="select" name="involvement">
                  <option value="" disabled>
                    How do you want to be involved?
                  </option>
                  <option value="stay-in-loop">Stay in the loop</option>
                  <option value="support-project">Support the project</option>
                </Field>
                <ErrorMessage
                  className="errorMsg"
                  name="involvement"
                  component="small"
                />
              </div>
            </Involvement>

            <Message className="fieldset">
              <label htmlFor="message" className="label">
                How do you want to support the project?
              </label>
              <Field
                className="input-textarea"
                name="message"
                component="textarea"
                rows="3"
              />
              <ErrorMessage
                className="errorMsg"
                name="message"
                component="small"
              />
            </Message>
            <Consent className="fieldset">
              <div>
                <Field className="input" type="checkbox" name="consent" />
                <label htmlFor="consent" className="label">
                  I consent to receive emails from this website and my data
                  stored to be used for communication purposes.
                </label>
              </div>

              <ErrorMessage
                className="errorMsg"
                name="consent"
                component="small"
              />
            </Consent>
            <Submit>
              <input
                name="submit"
                type="submit"
                disabled={isSubmitting}
                value="Send"
                className="button"
              />
            </Submit>
            {
              //  Success Message
              isSuccess && (
                <div className="success">
                  <p>Thank you for your message! We'll be in touch soon.</p>
                </div>
              )
            }
            {
              //  Error Message
              isError && (
                <div className="error">
                  <p>
                    Something went wrong! Could you please try again or email us
                    directly.
                  </p>
                </div>
              )
            }
          </Form>
        )}
      </Formik>
    </Holder>
  );
}

export default ContactForm;
