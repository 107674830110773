import React from "react";
import ContactForm from "./ContactForm";
import sticker from "../assets/images/our-house-sticker.png";
import styled from "styled-components";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  border-right: 0.2rem solid ${(props) => props.theme.colours.white};
  padding-right: 2rem;

  @media (${(props) => props.theme.breakpoints.md}) {
    margin: 2rem 0 4rem;
    gap: 4rem;
  }

  @media (${(props) => props.theme.breakpoints.xl}) {
    margin: 3rem 0 5rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  p {
    text-align: right;
  }

  h2 {
    font-family: "Poppins", sans-serif;
  }

  img {
    width: 30vw;
    height: auto;

    @media (${(props) => props.theme.breakpoints.sm}) {
      width: 15vw;
    }

    @media (${(props) => props.theme.breakpoints.md}) {
      width: 20vw;
    }
  }
`;

const ContactSection = () => {
  return (
    <div>
      <Header>
        <img src={sticker} alt="Sticker of the our house logo" />
        <div>
          <h2>Get Involved</h2>
          <p>
            We’re building an alliance. If you want to get involved, please
            reach out to us via the form below.
          </p>
        </div>
      </Header>

      <ContactForm />
    </div>
  );
};

export default ContactSection;
