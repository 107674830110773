import React from "react";
import styled from "styled-components";

const Holder = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.5rem 1.5rem;

  @media (${(props) => props.theme.breakpoints.md}) {
    padding: 0.5rem 2rem;
  }
  p {
    font-size: 0.8rem;
    margin: 0;
  }
`;

const Footer = () => {
  return (
    <Holder>
      <p className="small">© {new Date().getFullYear()} Our House</p>
    </Holder>
  );
};

export default Footer;
