import React from "react";
import styled from "styled-components";
import logo from "../assets/images/logo-house.png";

const Holder = styled.div`
  min-height: 80vh;
  padding-bottom: 2rem;

  @media (${(props) => props.theme.breakpoints.md}) {
    min-height: calc(100vh - 4rem);
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-left: 0.2rem solid ${(props) => props.theme.colours.white};
  padding-left: 2rem;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.md}) {
    padding-left: 3rem;
  }
`;

const Headers = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0;
    margin-bottom: 0.7rem;
    font-size: 4.5rem;
    line-height: 1;

    @media (${(props) => props.theme.breakpoints.md}) {
      margin-bottom: 0.3rem;
      line-height: 1.1;
    }
  }
  h2 {
    margin: 0;
    font-size: 1.8rem;

    @media (${(props) => props.theme.breakpoints.sm}) {
      max-width: 70vw;
    }
    @media (${(props) => props.theme.breakpoints.md}) {
      max-width: 50vw;
    }
  }
`;

const LogoHolder = styled.div`
  img {
    width: 28vw;
    height: auto;

    @media (${(props) => props.theme.breakpoints.sm}) {
      width: 24vw;
    }

    @media (${(props) => props.theme.breakpoints.md}) {
      width: 20vw;
    }

    @media (${(props) => props.theme.breakpoints.lg}) {
      width: 12vw;
    }
  }
`;

const Hero = () => {
  return (
    <Holder>
      <Inner>
        <LogoHolder>
          <img src={logo} alt="logo for our house" />
        </LogoHolder>
        <Headers>
          <h1>Our House</h1>
          <h2>Reimagining democracy together
          </h2>
        </Headers>
      </Inner>
    </Holder>
  );
};

export default Hero;
